import React, { useEffect, useRef, useState } from 'react';
import './DataTableForm.scss';
import {
    DataTable,
    DataTableColumn,
    DataTableRow,
    DataTableProgressStatusList,
    DataTableViewProgressStatus,
    TableViewProgressStatusList,
    DataTableProgressStatus,
    isCellAvailableByLogic,
    isTableColumnVisibleByLogic,
} from '../../../models/DataRequestHub/DataTable';
import {
    GridColDef,
    GridColumnHeaderParams,
    GridPreProcessEditCellProps,
    GridValidRowModel,
    useGridApiRef,
} from '@mui/x-data-grid';
import EditableTable from '../../../components/EditableTable/EditableTable';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import { CustomIdConstants } from '../../../models/DataRequestHub/CustomIdConstants';
import DataTableFormActionColumn from './DataTableFormActionColumn';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    ProjectVariable,
} from '../../../models/DataRequestHub/ProjectVariable';
import { objectToQueryString } from '../../../utils/queryHelper';
import { NumericColumn } from './NumericColumn/NumericColumn';
import moment from 'moment';
import { TimeZone, TimeZoneHelper } from '../../../utils/timeZoneHelper';
import { EditorTypeEnum } from '../../../models/DataRequestHub/ProjectEditorEnum';
import { Box } from '@mui/material';
import { Badge, Button } from 'reactstrap';
import {
    ResetCellInfo,
    resetCellSeenStatusByIds,
    resetCellSeenStatusByViewId,
} from '../../../models/DataRequestHub/slices/data-request-notifications.slice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { ExpandedText } from '../ExpandedText/ExpandedText';
import { Tooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';
import TextInputColumn from '../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';

interface DataTableFormProps {
    projectId: number;
    variables: ProjectVariable[];
    dataTable: DataTable;
    selectedTableViewId: number;
    isAdminView: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
    markCellsAsSeen(projectId: number, tableId: number, viewId: number): void;
    isReadonlyMode: boolean;
}

export const DataTableForm = (props: DataTableFormProps) => {
    const apiRef = useGridApiRef();
    const [tableData, setTableData] = useState<DataTableRow[]>([]);
    const gridTableRef = useRef(null);
    const axios = useStateSelector((s) => s.core.axios);
    const [dataTable, setDataTable] = useState<DataTable>(null);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [isStatusUpdateInProcess, setIsStatusUpdateInProcess] =
        useState(false);
    const [fullScreenEnabled, setFullScreenEnabled] = useState(false);
    const [markAsCompleteState, setMarkAsCompleteState] = useState(false);
    const [isMarkAsCompleteModified, setIsMarkAsCompleteModified] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isForceUpdate, setIsForceUpdate] = useState(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const [timeZone] = useState<TimeZone>(
        TimeZoneHelper.getTimeZoneByDate(new Date())
    );
    const cellsNotificationsStatus = useStateSelector(
        (s) => s.dataRequestsNotifications.tableCellsSeenStatus
    );

    const tableNotificationStatus = useStateSelector((s) =>
        s.dataRequestsNotifications.tablesNotificationsStatus.find(
            (f) => f.tableId === props.dataTable.id
        )
    );

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            axios
                .post(
                    `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableSnapshots/file`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                .then((response) => {
                    if (response.status !== 200) {
                        const message = (response as any)?.response?.data
                            ?.detail;
                        createNotification(
                            message ??
                                'An error occurred while processing file',
                            'error'
                        );
                    }
                    fileInputRef.current.value = '';
                    props.updateProjectsData();
                });
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const dispatch = useDispatch();

    const getCurrentTableView = () =>
        props.dataTable.views.find((x) => x.id === props.selectedTableViewId);

    const getCurrentTableViewColumns = () => {
        const selectedView = getCurrentTableView();

        const columnIds =
            selectedView?.viewColumns
                .sort(
                    (a, b) => a.overridenDisplayOrder - b.overridenDisplayOrder
                )
                .filter((viewColumn) => viewColumn.isVisible)
                .map((viewColumn) => viewColumn.customColumnId) ??
            props.dataTable.columns
                .filter((column) => {
                    return isTableColumnVisibleByLogic(
                        column,
                        props.dataTable.columns
                    );
                })
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((tableColumn) => tableColumn.customColumnId);

        return columnIds
            .map((id) => {
                return props.dataTable.columns.find(
                    (tableColumn) =>
                        tableColumn.customColumnId === id &&
                        tableColumn.isVisible
                );
            })
            .filter((x) => x != null);
    };

    const getVariableOptionsByName = (name: string) => {
        const variable = props.variables.find((x) => x.name === name);
        const result = variable.options.map((x) => x.option);
        return result;
    };

    const isReadonlyAccess = () => {
        const isTableComplete =
            props.dataTable.progressStatus === DataTableProgressStatus.Complete;
        const isCurrentViewConfirmed =
            getCurrentTableView()?.progressStatus ===
            DataTableViewProgressStatus.Confirmed;

        return isTableComplete || isCurrentViewConfirmed;
    };

    const columns = (): GridColDef[] => {
        const viewColumns = getCurrentTableViewColumns().map((x) => {
            switch (x.answerType) {
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Form
                ).name:
                    return getDropDownColumn(x);
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Number
                ).name:
                    return getNumericColumn(x);

                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Date
                ).name:
                    return getDateColumn(x);
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Text
                ).name:
                    return getTextInputColumn(x);

                default:
                    return getTextInputColumn(x);
            }
        });

        if (props.isAdminView) {
            return viewColumns;
        }

        const anyNewRows = tableNotificationStatus?.rowsSeenStatus?.some(
            (rowStatus) => !rowStatus.isSeenByUser
        );

        const isNewTableView =
            !tableNotificationStatus?.viewsNotificationsStatus?.find(
                (f) => f.viewId === props.selectedTableViewId
            )?.isSeenByUser;

        const notificationColumn: GridColDef = {
            field: 'id',
            headerName: '',
            minWidth: 50,
            maxWidth: 50,
            width: 50,
            flex: 0.5,
            type: 'string',
            editable: false,
            renderCell: (params) => {
                const targetRow = tableNotificationStatus.rowsSeenStatus.find(
                    (row) => params.row.customRowId === row.rowCustomId
                );

                return targetRow && !targetRow.isSeenByUser ? (
                    <Box className="MuiDataGrid-cellContent custom-render-cell">
                        <Badge className="cell-notification-badge unread-comments-badge">
                            1
                        </Badge>
                    </Box>
                ) : (
                    <></>
                );
            },
            sortable: false,
        } as GridColDef;

        return anyNewRows &&
            !props.isAdminView &&
            !tableNotificationStatus.isNewProject &&
            !tableNotificationStatus.isNew &&
            !isNewTableView
            ? [notificationColumn, ...viewColumns]
            : viewColumns;
    };

    const isCellAvailable = (targetColumn: DataTableColumn, gridRow: any) => {
        const viewcColumnsIds = props.dataTable.views.flatMap((view) =>
            view.viewColumns.map((viewColumn) => viewColumn.customColumnId)
        );

        const celanRows = clearRowsFromCellProperties([gridRow]).find((f) => f);
        const result = isCellAvailableByLogic(
            targetColumn,
            celanRows,
            viewcColumnsIds,
            props.dataTable.columns
        );
        return result;
    };

    const getDateColumn = (columnDefinition: DataTableColumn): GridColDef => {
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            type: 'date',
            cellClassName: (params) => {
                let resultClasses = 'cell-date-input date-picker tooltip-cell';

                if (!isCellAvailable(columnDefinition, params.row)) {
                    resultClasses = `${resultClasses} disabled`;
                }

                return resultClasses;
            },
            editable: !isReadonlyAccess() && !props.isReadonlyMode,
            valueFormatter: (params: any) =>
                params?.value ? moment(params?.value).format('L') : null,
            valueSetter: (params) => {
                const { row, value } = params;
                const valueWithTImeZoneOffset = TimeZoneHelper.parseUtcDate(
                    new Date(value),
                    timeZone
                );

                const isDateValid = TimeZoneHelper.isDateValid(
                    valueWithTImeZoneOffset
                );

                return {
                    ...row,
                    [columnDefinition.customColumnId]: isDateValid
                        ? valueWithTImeZoneOffset.toISOString()
                        : '',
                };
            },
            renderCell: (params) => {
                const isAvailable = isCellAvailable(
                    columnDefinition,
                    params.row
                );

                if (!isAvailable) {
                    return <></>;
                }

                const tooltipElement = (
                    <div>
                        {params?.value
                            ? moment(params?.value).format('L')
                            : null}
                    </div>
                );

                const isAnswerEmpty = !!!params.value;
                const targetView = getCurrentTableView();
                const isFormComplete =
                    targetView?.progressStatus ===
                        DataTableViewProgressStatus.Confirmed ||
                    props.dataTable.progressStatus ===
                        DataTableProgressStatus.Complete;

                const isAlertVisible =
                    isAnswerEmpty &&
                    !isFormComplete &&
                    !props.dataTable.isSnapshot;

                return isAlertVisible ? (
                    <div className="MuiDataGrid-cellContent empty-answer-cell-alert"></div>
                ) : (
                    <Box className="MuiDataGrid-cellContent custom-render-cell">
                        {getTooltip(
                            `${params?.field}-${params?.id ?? 0}`,
                            tooltipElement
                        )}
                        <div id={`${params?.field}-${params?.id ?? 0}`}>
                            {params.formattedValue}
                        </div>
                        {renderCellBadgeNotification(
                            columnDefinition.customColumnId,
                            params.row.customRowId
                        )}
                    </Box>
                );
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = !!!params.props.value;
                return { ...params.props, error: hasError };
            },
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    {getTooltip(
                        `header-${params?.field}`,
                        <>{params.colDef.headerName}</>
                    )}
                    <div id={`header-${params?.field}`}>
                        {params.colDef.headerName}
                    </div>
                    {!props.isAdminView ? (
                        renderHeaderColumnNotification(
                            columnDefinition.customColumnId
                        )
                    ) : (
                        <></>
                    )}
                </>
            ),
        } as GridColDef;

        return result;
    };

    const getNumericColumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            cellClassName: (params) => {
                let resultClasses = 'cell-text-input numeric-cell tooltip-cell';

                if (!isCellAvailable(columnDefinition, params.row)) {
                    resultClasses = `${resultClasses} disabled`;
                }

                return resultClasses;
            },
            editable: !isReadonlyAccess() && !props.isReadonlyMode,
            renderEditCell: (params: any) => (
                <NumericColumn
                    isReadonly={false}
                    formType={columnDefinition.formType}
                    {...params}
                />
            ),
            renderCell: (params: any) => {
                const isAvailable = isCellAvailable(
                    columnDefinition,
                    params.row
                );

                if (!isAvailable) {
                    return <></>;
                }

                const tooltipElement = (
                    <NumericColumn
                        isReadonly={true}
                        formType={columnDefinition.formType}
                        {...params}
                    />
                );

                const isAnswerEmpty = !!!params.value;
                const targetView = getCurrentTableView();
                const isFormComplete =
                    targetView?.progressStatus ===
                        DataTableViewProgressStatus.Confirmed ||
                    props.dataTable.progressStatus ===
                        DataTableProgressStatus.Complete;

                const isAlertVisible =
                    isAnswerEmpty &&
                    !isFormComplete &&
                    !props.dataTable.isSnapshot;

                return isAlertVisible ? (
                    <div className="MuiDataGrid-cellContent empty-answer-cell-alert"></div>
                ) : (
                    <Box className="MuiDataGrid-cellContent custom-render-cell">
                        {getTooltip(
                            `${params?.field}-${params?.id ?? 0}`,
                            tooltipElement
                        )}
                        <div id={`${params?.field}-${params?.id ?? 0}`}>
                            <NumericColumn
                                isReadonly={true}
                                formType={columnDefinition.formType}
                                {...params}
                            />
                        </div>
                        {renderCellBadgeNotification(
                            columnDefinition.customColumnId,
                            params.row.customRowId
                        )}
                    </Box>
                );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    {getTooltip(
                        `header-${params?.field}`,
                        <>{params.colDef.headerName}</>
                    )}
                    <div id={`header-${params?.field}`}>
                        {params.colDef.headerName}
                    </div>
                    {!props.isAdminView ? (
                        renderHeaderColumnNotification(
                            columnDefinition.customColumnId
                        )
                    ) : (
                        <></>
                    )}
                </>
            ),
        } as GridColDef;

        return result;
    };

    const getDropDownColumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        let valueOptions = [''];

        if (
            columnDefinition.formType ===
            FormTypeVariableNamesList.find(
                (u) => u.id === FormTypeVariable.DropDown
            ).name
        ) {
            valueOptions = getVariableOptionsByName(columnDefinition.formList);
        } else if (
            columnDefinition.formType ===
            FormTypeVariableNamesList.find(
                (u) => u.id === FormTypeVariable.YesNo
            ).name
        ) {
            valueOptions = ['Yes', 'No'];
        }
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            type: 'singleSelect',
            valueOptions: valueOptions,
            cellClassName: (params) => {
                let resultClasses = 'cell-text-input tooltip-cell';

                if (!isCellAvailable(columnDefinition, params.row)) {
                    resultClasses = `${resultClasses} disabled`;
                }

                return resultClasses;
            },
            editable: !isReadonlyAccess() && !props.isReadonlyMode,
            renderCell: (params) => {
                const isAvailable = isCellAvailable(
                    columnDefinition,
                    params.row
                );

                if (!isAvailable) {
                    return <></>;
                }

                const tooltipElement = <div>{params.value}</div>;

                const isAnswerEmpty = !!!params.value;
                const targetView = getCurrentTableView();
                const isFormComplete =
                    targetView?.progressStatus ===
                        DataTableViewProgressStatus.Confirmed ||
                    props.dataTable.progressStatus ===
                        DataTableProgressStatus.Complete;

                const isAlertVisible =
                    isAnswerEmpty &&
                    !isFormComplete &&
                    !props.dataTable.isSnapshot;

                return isAlertVisible ? (
                    <div className="MuiDataGrid-cellContent empty-answer-cell-alert"></div>
                ) : (
                    <Box className="MuiDataGrid-cellContent custom-render-cell">
                        {getTooltip(
                            `${params?.field}-${params?.id ?? 0}`,
                            tooltipElement
                        )}
                        <div id={`${params?.field}-${params?.id ?? 0}`}>
                            {params.formattedValue}
                        </div>
                        {renderCellBadgeNotification(
                            columnDefinition.customColumnId,
                            params.row.customRowId
                        )}
                    </Box>
                );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    {getTooltip(
                        `header-${params?.field}`,
                        <>{params.colDef.headerName}</>
                    )}
                    <div id={`header-${params?.field}`}>
                        {params.colDef.headerName}
                    </div>
                    {!props.isAdminView ? (
                        renderHeaderColumnNotification(
                            columnDefinition.customColumnId
                        )
                    ) : (
                        <></>
                    )}
                </>
            ),
        } as GridColDef;

        return result;
    };

    const getTextInputColumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        const result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            type: 'string',
            cellClassName: (params) => {
                let resultClasses = 'cell-text-input';

                if (!isCellAvailable(columnDefinition, params.row)) {
                    resultClasses = `${resultClasses} disabled`;
                }

                return resultClasses;
            },
            editable: !isReadonlyAccess() && !props.isReadonlyMode,
            valueSetter: (params) => {
                const { row, value } = params;
                return {
                    ...row,
                    [columnDefinition.customColumnId]: value.trim(),
                };
            },
            renderEditCell: (params) => <TextInputColumn {...params} />,
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    {getTooltip(
                        `header-${params?.field}`,
                        <>{params.colDef.headerName}</>
                    )}
                    <div id={`header-${params?.field}`}>
                        {params.colDef.headerName}
                    </div>
                    {!props.isAdminView ? (
                        renderHeaderColumnNotification(
                            columnDefinition.customColumnId
                        )
                    ) : (
                        <></>
                    )}
                </>
            ),
            renderCell: (params) => {
                const isAvailable = isCellAvailable(
                    columnDefinition,
                    params.row
                );

                if (!isAvailable) {
                    return <></>;
                }

                const tooltipElement = <div>{params.row[params.field]}</div>;

                const isAnswerEmpty = !!!params.value;
                const targetView = getCurrentTableView();
                const isFormComplete =
                    targetView?.progressStatus ===
                        DataTableViewProgressStatus.Confirmed ||
                    props.dataTable.progressStatus ===
                        DataTableProgressStatus.Complete;

                const isAlertVisible =
                    isAnswerEmpty &&
                    !isFormComplete &&
                    !props.dataTable.isSnapshot;

                return isAlertVisible ? (
                    <div className="MuiDataGrid-cellContent empty-answer-cell-alert"></div>
                ) : (
                    <Box className="MuiDataGrid-cellContent custom-render-cell">
                        {getTooltip(
                            `${params?.field}-${params?.id ?? 0}`,
                            tooltipElement
                        )}
                        <ExpandedText
                            content={params.row[params.field]}
                            uniqueId={`${params?.field}-${params?.id ?? 0}`}
                            heightToShowLinksPx={95}
                            className="MuiDataGrid-cellContent"
                        />

                        {renderCellBadgeNotification(
                            columnDefinition.customColumnId,
                            params.row.customRowId
                        )}
                    </Box>
                );
            },
        } as GridColDef;

        return result;
    };

    const getTooltip = (anchorId: string, content: React.JSX.Element) => {
        return createPortal(
            <Tooltip
                anchorId={anchorId}
                className="tooltip-content"
                id="event"
                delayShow={1000}
                style={{
                    maxWidth: '300px',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    zIndex: 9999,
                }}
                place="bottom">
                {content}
            </Tooltip>,
            document.body
        );
    };

    const renderCellBadgeNotification = (
        columnCustomId: string,
        rowCustomId: string
    ) => {
        if (props.isAdminView) return <></>;
        const cellNotificationStatus = cellsNotificationsStatus.find(
            (cell) =>
                props.dataTable.id === cell.tableId &&
                cell.customColumnId === columnCustomId &&
                cell.customRowId === rowCustomId
        );

        const targetRowStatus = tableNotificationStatus?.rowsSeenStatus?.find(
            (row) => row.rowCustomId === cellNotificationStatus?.customRowId
        );

        const targetView =
            tableNotificationStatus?.viewsNotificationsStatus.find(
                (viewStatus) => viewStatus.viewId === props.selectedTableViewId
            );

        const targetColumnStatus = props.selectedTableViewId
            ? targetView?.columnsSeenStatus.find(
                  (columnStatus) =>
                      columnStatus.columnCustomId ===
                      cellNotificationStatus?.customColumnId
              )
            : null;

        const isNotificationsVisible =
            !cellNotificationStatus?.isSeenByUser &&
            !props.isAdminView &&
            !cellNotificationStatus?.isNewTable &&
            targetRowStatus?.isSeenByUser &&
            targetColumnStatus?.isSeenByUser &&
            targetView?.columnsSeenStatus?.length &&
            !props.dataTable.isSnapshot;

        return isNotificationsVisible ? (
            <Badge className="cell-notification-badge unread-comments-badge">
                1
            </Badge>
        ) : (
            <></>
        );
    };

    const renderHeaderColumnNotification = (customColumnId: string) => {
        const targetView =
            tableNotificationStatus?.viewsNotificationsStatus?.find(
                (view) => view.viewId === props.selectedTableViewId
            );
        const targetColumn = targetView?.columnsSeenStatus.find(
            (column) => column.columnCustomId === customColumnId
        );
        return props.selectedTableViewId &&
            !props.isAdminView &&
            !targetColumn?.isSeenByUser &&
            !tableNotificationStatus?.isNew &&
            !props.dataTable.isSnapshot ? (
            <Badge className="cell-notification-badge unread-comments-badge">
                1
            </Badge>
        ) : (
            <></>
        );
    };

    const resetCellNotifications = (cellIds: ResetCellInfo[]) => {
        dispatch(
            resetCellSeenStatusByIds({
                cellsInfoToReset: cellIds,
                tableId: props.dataTable.id,
                viewId: props.selectedTableViewId,
            })
        );
    };

    const updateTableData = () => {
        let rowList = props.dataTable.rows.filter(
            (x) => (x.isVisible && !x.isRemovalRequested) || props.isAdminView
        );
        const columns = props.dataTable.columns;

        const result = rowList.map((row) => {
            const newRow = { ...row } as any;

            row.cells.forEach((y) => {
                const column = columns.find(
                    (x) => x.customColumnId === y.customColumnId
                );
                if (column) {
                    newRow[column.customColumnId] = y.answerText;
                }
            });
            return newRow;
        });

        setTableData(result);
    };

    const clearRowsFromCellProperties = (
        updatedRows: DataTableRow[]
    ): DataTableRow[] => {
        return updatedRows.map((updatedRow) => {
            const newCells = updatedRow.cells.map((cell) => {
                const newAnswerText = updatedRow[
                    cell.customColumnId as keyof typeof updatedRow
                ] as string;
                const isNullOrUndefined =
                    newAnswerText === undefined || newAnswerText === null;
                return {
                    ...cell,
                    answerText: isNullOrUndefined
                        ? cell.answerText
                        : newAnswerText,
                };
            });

            return {
                ...updatedRow,
                cells: newCells,
            };
        });
    };

    const validateAllRows = () => {
        let isValid = true;

        return isValid;
    };

    const updateRows = (
        newRows: (oldRows: DataTableRow[]) => DataTableRow[]
    ): void => {
        const result = newRows(tableData);
        setTableData(result);
        debounceUpdateDataTableRows(result);
    };

    const filterUpdatedRows = (tableRows: DataTableRow[]) => {
        const updatedRows = tableRows.filter((tableRow) => {
            const originRow = dataTable.rows.find((f) => f.id === tableRow.id);

            if (originRow) {
                const hasAnyCellDifference = originRow.cells.some(
                    (originCell) => {
                        const tableCell = tableRow.cells.find(
                            (cell) => cell.id === originCell.id
                        );

                        if (tableCell) {
                            const tableCellAnswer = tableCell.answerText ?? '';
                            const originalTableCellAnswer =
                                originCell.answerText ?? '';
                            return (
                                tableCellAnswer !== originalTableCellAnswer ||
                                originRow.isVisible !== tableRow.isVisible
                            );
                        } else {
                            return false;
                        }
                    }
                );

                return hasAnyCellDifference;
            }

            return false;
        });

        return updatedRows;
    };

    const debounceUpdateDataTableRows = (tableRows: DataTableRow[]) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            const celanRows = clearRowsFromCellProperties(tableRows);
            const updatedRows = filterUpdatedRows(celanRows);

            if (!updatedRows.length) {
                return;
            }

            axios
                .put(
                    `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                    {
                        targetViewId: props.selectedTableViewId,
                        rows: updatedRows,
                    }
                )
                .then((response) => {
                    if (response.status !== 200) {
                        const message = (response as any)?.response?.data
                            ?.detail;
                        createNotification(
                            message ?? 'An error occurred while updating row',
                            'error'
                        );
                    }
                    setIsForceUpdate(true);
                    props.updateProjectsData();
                });
        }, 2000);
    };

    useEffect(() => {
        validateAllRows();
    }, [tableData]);

    useEffect(() => {
        if (
            JSON.stringify(dataTable?.rows) !==
                JSON.stringify(props.dataTable.rows) ||
            JSON.stringify(dataTable?.columns) !==
                JSON.stringify(props.dataTable.columns)
        ) {
            updateTableData();
            setDataTable(props.dataTable);
            setIsForceUpdate(false);
        }
    }, [props.dataTable]);

    useEffect(() => {
        if (!props.isAdminView && props.selectedTableViewId) {
            props.markCellsAsSeen(
                props.projectId,
                props.dataTable.id,
                props.selectedTableViewId
            );
        }

        return () => {
            if (!props.isAdminView && !props.dataTable.isSnapshot) {
                dispatch(
                    resetCellSeenStatusByViewId({
                        tableId: props.dataTable.id,
                        viewId: props.selectedTableViewId,
                    })
                );
            }
        };
    }, []);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'F11') {
                event.preventDefault();
                if (fullScreenEnabled) {
                    exitFullScreen();
                } else {
                    enterFullScreen();
                }
            }
            if (event.key === 'Escape' && fullScreenEnabled) {
                exitFullScreen();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [fullScreenEnabled]);

    useEffect(() => {
        if (props.selectedTableViewId && !isMarkAsCompleteModified) {
            const selectedTableView = getCurrentTableView();
            const markAsComplete = props.isAdminView
                ? selectedTableView?.isComplete
                : selectedTableView?.isReadyForReview;

            setMarkAsCompleteState(markAsComplete);
        }
    }, [props]);

    const handleAddNewRow = () => {
        if (isRequestPending) return;

        setIsRequestPending(true);
        const ids = props.dataTable.rows.map((x) => x.customRowId);
        const newTableRowId =
            CustomIdConstants.getNextDataTableRowCustomId(ids);
        axios
            .post(
                `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                { customDataTableRowId: newTableRowId }
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occurred while adding row',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsRequestPending(false);
            });
    };

    const renderAddNewRow = () => (
        <ButtonLoader
            onClick={handleAddNewRow}
            buttonText={'+ Add New Row'}
            className={'btn btn-primary'}
            loaderButtonText={''}
            isLoading={isRequestPending}
            disabled={
                isRequestPending ||
                props.dataTable.progressStatus ===
                    DataTableProgressStatus.Complete
            }></ButtonLoader>
    );

    const getRowId = (row: GridValidRowModel) => row.customRowId;

    const customActionColumn = DataTableFormActionColumn({
        setRows: updateRows,
        gridApiRef: apiRef,
        getRowId: getRowId,
        projectId: props.projectId,
        dataTableId: props.dataTable.id,
        updateProjectsData: props.updateProjectsData,
        isAdmin: props.isAdminView,
    });

    const handleTableViewStatusChange = (
        newStatus: DataTableViewProgressStatus
    ) => {
        const currentTableView = getCurrentTableView();
        setIsStatusUpdateInProcess(true);
        axios
            .put(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableViews/${currentTableView.id}/progressStatus`,
                { progressStatus: newStatus }
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while updating view status. Please, try again'
                    );
                } else {
                    props.updateProjectsData();
                }
            })
            .finally(() => {
                setIsStatusUpdateInProcess(false);
            });
    };

    const updateMarkAsCompleteState = () => {
        setIsLoading(true);

        const updateUrl = props.isAdminView
            ? `/isComplete?${objectToQueryString({
                  isComplete: markAsCompleteState,
              })}`
            : `/isReadyForApproval?${objectToQueryString({
                  isReadyForApproval: markAsCompleteState,
              })}`;

        axios
            .put(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableViews/${props.selectedTableViewId}${updateUrl}`
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while updating complete status',
                        'error'
                    );
                    setMarkAsCompleteState(!markAsCompleteState);
                } else {
                    props.updateProjectsData();
                }
            })
            .finally(() => {
                setIsLoading(false);
                setIsMarkAsCompleteModified(false);
            });
    };

    const getInitialMarkAsCompleteState = () => {
        const selectedTableView = getCurrentTableView();
        const initialMarkAsCompleteState = props.isAdminView
            ? selectedTableView.isComplete
            : selectedTableView.isReadyForReview;

        return initialMarkAsCompleteState;
    };

    const isViewAnswersComplete = () => {
        const currentView = getCurrentTableView();
        const includedColumnCustomIds = currentView.viewColumns.flatMap(
            (viewColumn) => viewColumn.customColumnId
        );
        const visibleCells = props.dataTable.rows
            .filter((row) => row.isVisible)
            .flatMap((row) => {
                const cells = row.cells.filter((cell) => {
                    const targetColumn = props.dataTable.columns.find(
                        (column) =>
                            column.customColumnId === cell.customColumnId
                    );

                    const allViewCustomColumnIds =
                        props.dataTable.views.flatMap((view) =>
                            view.viewColumns.map(
                                (viewColumn) => viewColumn.customColumnId
                            )
                        );
                    const isAvailableByLogic = isCellAvailableByLogic(
                        targetColumn,
                        row,
                        allViewCustomColumnIds,
                        props.dataTable.columns
                    );
                    return (
                        includedColumnCustomIds.some(
                            (includedColumnCustomId) =>
                                includedColumnCustomId === cell.customColumnId
                        ) && isAvailableByLogic
                    );
                });

                return cells;
            });

        const isVisibleCellsAnswerComplete =
            visibleCells.every(
                (cell) =>
                    cell.answerText !== undefined &&
                    cell.answerText !== null &&
                    cell.answerText !== ''
            ) &&
            currentView.viewColumns.length &&
            visibleCells.length;

        return isVisibleCellsAnswerComplete;
    };

    const renderViewStautsBar = () => {
        const selectedTableView = getCurrentTableView();

        const statusLabel = props.isAdminView ? (
            <>
                <div className="table-view-progress-status-dropdown-wrapper">
                    <div className="status">{'Status: '}</div>
                    <select
                        disabled={isStatusUpdateInProcess}
                        value={selectedTableView.progressStatus}
                        style={{
                            color: TableViewProgressStatusList.find(
                                (x) =>
                                    x.id ==
                                    (selectedTableView.progressStatus ?? 0)
                            ).color,
                        }}
                        onChange={(event) => {
                            const value = Number(event.target.value);
                            handleTableViewStatusChange(value);
                        }}
                        name="table-view-progress-status"
                        className="table-view-progress-status-dropdown form-control">
                        {TableViewProgressStatusList.map((item, index) => (
                            <option
                                style={{ color: item.color }}
                                key={index + item.id + item.name}
                                value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        ) : (
            <div className="table-view-progress-status-dropdown-wrapper">
                <div className="status">{'Status: '}</div>
                <div
                    style={{
                        color: TableViewProgressStatusList.find(
                            (x) => x.id == selectedTableView.progressStatus
                        ).color,
                        fontFamily: 'SegoeUI-Bold',
                    }}>
                    {
                        TableViewProgressStatusList.find(
                            (x) => x.id == selectedTableView.progressStatus
                        ).name
                    }
                </div>
            </div>
        );

        return (
            <>
                {statusLabel}
                <div className="mark-as-complete-section">
                    <input
                        disabled={
                            !isViewAnswersComplete() || isReadonlyAccess()
                        }
                        checked={markAsCompleteState}
                        type="checkbox"
                        onChange={(event) => {
                            setMarkAsCompleteState(event.target.checked);
                            setIsMarkAsCompleteModified(true);
                        }}></input>
                    <label>{'Mark as Complete'}</label>
                    <ButtonLoader
                        buttonText={'Save'}
                        loaderButtonText={''}
                        disabled={
                            getInitialMarkAsCompleteState() ===
                            markAsCompleteState
                        }
                        isLoading={isLoading}
                        onClick={updateMarkAsCompleteState}
                        className="btn btn-primary"
                    />
                </div>
            </>
        );
    };

    const renderTableStatus = () => (
        <div className="table-view-progress-status-dropdown-wrapper">
            <div className="status">{'Status: '}</div>
            <div
                style={{
                    color: DataTableProgressStatusList.find(
                        (x) => x.id == (props.dataTable.progressStatus ?? 0)
                    ).color,
                    fontFamily: 'SegoeUI-Bold',
                }}>
                {
                    DataTableProgressStatusList.find(
                        (x) => x.id == (props.dataTable.progressStatus ?? 0)
                    ).name
                }
            </div>
        </div>
    );

    const exitFullScreen = () => {
        setFullScreenEnabled(false);
        const header = document.querySelector(
            '.app-header.header-shadow'
        ) as HTMLElement;
        if (header) {
            header.style.display = 'flex';
        }
        const leftMenu = document.querySelector(
            '.app-sidebar.sidebar-shadow'
        ) as HTMLElement;
        if (leftMenu) {
            leftMenu.style.display = 'flex';
        }
    };

    const enterFullScreen = () => {
        setFullScreenEnabled(true);
        const header = document.querySelector(
            '.app-header.header-shadow'
        ) as HTMLElement;
        if (header) {
            header.style.display = 'none';
        }
        const leftMenu = document.querySelector(
            '.app-sidebar.sidebar-shadow'
        ) as HTMLElement;
        if (leftMenu) {
            leftMenu.style.display = 'none';
        }
    };

    return (
        <div
            className={
                fullScreenEnabled
                    ? 'data-table-view-host full-screen'
                    : 'data-table-view-host'
            }>
            <div className="status-tool-bar">
                {props.isAdminView && (
                    <div style={{ marginRight: 'auto' }}>
                        <input
                            hidden={true}
                            type="file"
                            name="file"
                            ref={fileInputRef}
                            accept=".xlsx, .xls"
                            id="excel-file-input"
                            className="upload-file-input"
                            multiple={false}
                            onChange={handleFileChange}
                        />
                        <Button onClick={handleButtonClick} color="primary">
                            Update Table
                        </Button>
                    </div>
                )}

                {props.isReadonlyMode ? (
                    <></>
                ) : props.selectedTableViewId ? (
                    renderViewStautsBar()
                ) : (
                    renderTableStatus()
                )}

                <div className="full-screen-button">
                    {fullScreenEnabled ? (
                        <FontAwesomeIcon
                            icon={faCompressAlt}
                            onClick={() => {
                                exitFullScreen();
                            }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faExpandAlt}
                            onClick={() => {
                                enterFullScreen();
                            }}
                        />
                    )}
                </div>
            </div>
            <EditableTable
                editorType={EditorTypeEnum.DataTableData}
                columns={columns()}
                rows={tableData}
                setRows={updateRows}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
                customActionColumn={customActionColumn}
                disableVisibilityColumn={
                    !props.isAdminView ||
                    isReadonlyAccess() ||
                    props.isReadonlyMode
                }
                disableActionColumn={isReadonlyAccess() || props.isReadonlyMode}
                onCellClick={(params) => {
                    if (!props.isAdminView && !props.dataTable.isSnapshot) {
                        const cellToReset: ResetCellInfo = {
                            customColumnId: params.field,
                            customRowId: params.id as string,
                        };
                        resetCellNotifications([cellToReset]);
                    }
                }}
            />
            {!props.isReadonlyMode && renderAddNewRow()}
        </div>
    );
};
