import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import EditableTable from '../../../../../components/EditableTable/EditableTable';
import {
    GridColDef,
    GridEditDateCell,
    GridEditSingleSelectCell,
    GridPreProcessEditCellProps,
    GridRowId,
    GridValidRowModel,
    GridValueOptionsParams,
    useGridApiRef,
} from '@mui/x-data-grid';
import TextInputColumn from '../../../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';
import { ExpandedText } from '../../../../DataRequest/ExpandedText/ExpandedText';
import {
    removeErrorClassFromCellContent,
    validateFormList,
    validateFormType,
    validateGridEditorRowDisplayOrder,
    validateRequiredCell,
    validateUniqueItemName,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import { DataTableColumn } from '../../../../../models/DataRequestHub/DataTable';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    NumericFormatVariableNamesList,
    ProjectVariable,
    YesNoDropdownOptionsList,
} from '../../../../../models/DataRequestHub/ProjectVariable';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';
import DisplayLogicSwitcherColumn from '../../../../../components/EditableTable/CustomColumns/DisplayLogicSwitcherColumn';
import { NumericColumn } from '../../../../DataRequest/DataTableForm/NumericColumn/NumericColumn';
import { Box } from '@mui/material';
import './DataTableEditor.scss';
import moment from 'moment';
import { TimeZone, TimeZoneHelper } from '../../../../../utils/timeZoneHelper';

interface DataTableEditorInterface {
    tableColumns: DataTableColumn[];
    setTableColumns(rows: DataTableColumn[]): void;
    isLoading: boolean;
    answerType: ProjectVariable;
    formType: ProjectVariable;
    numericFormat: ProjectVariable;
    notSystemVariables: ProjectVariable[];
}

const DataTableEditor = forwardRef((props: DataTableEditorInterface, ref) => {
    const apiRef = useGridApiRef();
    const {
        tableColumns: tableColumns,
        setTableColumns: setTableColumns,
        isLoading,
        answerType,
        formType,
        numericFormat,
        notSystemVariables,
    } = props;

    const [timeZone] = useState<TimeZone>(
        TimeZoneHelper.getTimeZoneByDate(new Date())
    );

    const gridTableRef = useRef(null);
    useImperativeHandle(ref, () => ({
        addRow() {
            gridTableRef.current.addRow();
        },
        validateAllRows() {
            return validateAllRows();
        },
        getEditorState() {
            return apiRef.current.state;
        },
    }));

    const getConditionValueOptions = (
        currentColumn: DataTableColumn
    ): string[] => {
        const parentColumn = getColumnGridModelByCusomtId(
            currentColumn.logicParentCustomId
        );

        const formType = FormTypeVariableNamesList.find(
            (f) => f.name === parentColumn?.formType
        );

        if (formType?.id === FormTypeVariable.YesNo) {
            return YesNoDropdownOptionsList.map((m) => m.name).reverse();
        }

        if (
            formType?.id === FormTypeVariable.DropDown &&
            parentColumn?.formList
        ) {
            const variableOptions =
                notSystemVariables.find(
                    (variable) => variable.name === parentColumn?.formList
                )?.options ?? [];
            return variableOptions?.map((option) => option.option);
        }

        return [];
    };

    const getColumnGridModelByCusomtId = (
        customColumnId: string
    ): DataTableColumn => {
        const latestStateMap = apiRef.current.getRowModels();
        const latestStateArray = Array.from(
            latestStateMap.values()
        ) as DataTableColumn[];
        return latestStateArray?.find(
            (questionModel) => questionModel.customColumnId === customColumnId
        );
    };

    const getParentColumnIdOptions = (currentColumn: DataTableColumn) => {
        const currentCustomColumnId = currentColumn.customColumnId;
        const parentCustomIds = props.tableColumns.map(
            (column) => column.customColumnId
        );

        const currentCustomIdIndex = parentCustomIds.indexOf(
            currentCustomColumnId
        );
        parentCustomIds.splice(currentCustomIdIndex, 1);

        return parentCustomIds;
    };

    const updateRows = (
        newRows: (oldColumns: DataTableColumn[]) => DataTableColumn[]
    ): void => {
        const result = newRows(tableColumns);
        setTableColumns(result);
    };

    const getColumnByCustomId = (customColumnId: string) => {
        return props.tableColumns.find(
            (column) => column.customColumnId === customColumnId
        );
    };

    const getRowId = (row: GridValidRowModel) => {
        return row.customColumnId;
    };

    const displayLogicSwitcherColumn = DisplayLogicSwitcherColumn({
        setRows: updateRows,
        gridApiRef: apiRef,
        getRowId: getRowId,
    });

    const isParentAnswerDescribed = (tableColumn: DataTableColumn) => {
        const parentColumn = getColumnByCustomId(
            tableColumn?.logicParentCustomId
        );

        if (!parentColumn) {
            return false;
        }

        const definedTypes = AnswerTypeVariableNamesList.filter(
            (f) =>
                f.id === AnswerTypeVariable.Date ||
                f.id === AnswerTypeVariable.Text
        );

        const definedFormOptions = [
            ...FormTypeVariableNamesList.filter(
                (f) => f.id === FormTypeVariable.YesNo
            ),
            ...NumericFormatVariableNamesList,
        ].map((definedFormTypeOption) => definedFormTypeOption.name);

        const isParentColumnAnswerIdentified =
            parentColumn?.formList ||
            definedFormOptions.some(
                (formOption) => formOption === parentColumn?.formType
            ) ||
            definedTypes.some((type) => type.name === parentColumn?.answerType);

        return isParentColumnAnswerIdentified;
    };

    const handleConditionValueDropdownChange = async (
        newValue: string,
        oldRowId: GridRowId
    ) => {
        await apiRef.current.setEditCellValue({
            id: oldRowId,
            field: 'conditionValue',
            value: newValue,
        });
    };

    const handleConditionDateValueChange = async (
        newValue: Date,
        oldRowId: GridRowId
    ) => {
        await apiRef.current.setEditCellValue({
            id: oldRowId,
            field: 'conditionValue',
            value: newValue,
        });
    };

    const removeReferencedConditionsByParentId = (customColumnId: string) => {
        const latestStateMap = apiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());

        const updatedStateArray = latestStateArray.map((row) => {
            const question = row as DataTableColumn;
            if (question.logicParentCustomId === customColumnId) {
                return {
                    ...row,
                    conditionValue: '',
                };
            }

            return row;
        });

        updateRows(() => updatedStateArray as DataTableColumn[]);
    };

    const handleParentColumnAnswerTypeChange = async (
        newValue: string,
        field: string,
        currentRowId: GridRowId,
        current: DataTableColumn
    ) => {
        await apiRef.current.setEditCellValue({
            id: currentRowId,
            field: field,
            value: newValue,
        });

        removeReferencedConditionsByParentId(current.customColumnId);
    };

    const handleParentColumnIdChange = (customColumnId: string) => {
        const latestStateMap = apiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());

        const updatedStateArray = latestStateArray.map((row) => {
            const question = row as DataTableColumn;
            if (question.customColumnId === customColumnId) {
                return {
                    ...row,
                    conditionValue: '',
                };
            }

            return row;
        });

        updateRows(() => updatedStateArray as DataTableColumn[]);
    };

    const columns = (): GridColDef[] => [
        {
            field: 'displayOrder',
            headerName: 'Display Order',
            minWidth: 140,
            flex: 0.25,
            type: 'number',
            cellClassName: (params) => {
                let cellClass = 'cell-input display-order';

                if (params.row.isSubQuestion) {
                    cellClass = `${cellClass} disabled`;
                }

                return cellClass;
            },
            editable: true,
            align: 'left',
            headerAlign: 'left',
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const value = params.props.value;
                const hasError = value <= 0;
                return { ...params.props, error: hasError };
            },
        },
        {
            field: 'customColumnId',
            headerName: 'C-ID',
            minWidth: 150,
            flex: 0.5,
            cellClassName: 'cell-text-input custom-column-id',
            editable: false,
            renderCell: (params) => (
                <ExpandedText
                    content={params.row.customColumnId}
                    uniqueId={`custom-column-id-input-${params?.id ?? 0}`}
                    heightToShowLinksPx={95}
                    className="MuiDataGrid-cellContent"
                />
            ),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                return { ...params.props };
            },
            renderEditCell: (params) => <TextInputColumn {...params} />,
        },
        {
            field: 'name',
            headerName: 'Column Name',
            minWidth: 150,
            flex: 0.5,
            cellClassName: 'cell-text-input display-name',
            editable: true,
            renderCell: (params) => (
                <ExpandedText
                    content={params.row.name}
                    uniqueId={`display-name-input-${params?.id ?? 0}`}
                    heightToShowLinksPx={95}
                    className="MuiDataGrid-cellContent"
                />
            ),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const value = params.props.value;

                const hasError = value?.trim().length <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => <TextInputColumn {...params} />,
        },
        {
            field: 'answerType',
            headerName: 'Answer Type',
            minWidth: 170,
            cellClassName: 'answer-type',
            flex: 0.5,
            type: 'singleSelect',
            valueOptions: answerType?.options.map((x) => x.option),
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value?.length <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    onValueChange={(_, newValue) =>
                        handleParentColumnAnswerTypeChange(
                            newValue,
                            params.field,
                            params.id,
                            params.row
                        )
                    }
                    {...params}
                />
            ),
        },
        {
            field: 'formType',
            headerName: 'Form Type',
            minWidth: 170,
            cellClassName: 'form-type',
            flex: 0.5,
            type: 'singleSelect',
            valueOptions: (params: GridValueOptionsParams<DataTableColumn>) => {
                switch (params.row.answerType) {
                    case 'Form':
                        return formType?.options.map((x) => x.option);
                    case 'Number':
                        return numericFormat?.options.map((x) => x.option);
                }
            },
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value?.length <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    onValueChange={(_, newValue) =>
                        handleParentColumnAnswerTypeChange(
                            newValue,
                            params.field,
                            params.id,
                            params.row
                        )
                    }
                    {...params}
                />
            ),
        },
        {
            field: 'formList',
            headerName: 'Form List',
            minWidth: 170,
            cellClassName: 'form-list',
            flex: 0.5,
            type: 'singleSelect',
            valueOptions: notSystemVariables.map((x) => x.name),
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value?.length <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    onValueChange={(_, newValue) =>
                        handleParentColumnAnswerTypeChange(
                            newValue,
                            params.field,
                            params.id,
                            params.row
                        )
                    }
                    {...params}
                />
            ),
        },
        displayLogicSwitcherColumn,
        {
            field: 'logicParentCustomId',
            headerName: 'Conditional Column',
            minWidth: 150,
            flex: 0.5,
            cellClassName: (params) => {
                let cellClass = 'cell-text-input logic-parent-column-id';

                const isLogicEnabled = params.row.isDisplayLogicEnabled;
                if (!isLogicEnabled) {
                    cellClass = `${cellClass} disabled`;
                }

                return cellClass;
            },
            type: 'singleSelect',
            valueOptions: (params) => getParentColumnIdOptions(params.row),
            editable: true,
            renderEditCell: (params) => (
                <GridEditSingleSelectCell
                    onValueChange={() => {
                        handleParentColumnIdChange(params.row.customColumnId);
                    }}
                    {...params}
                />
            ),
        },
        {
            field: 'conditionValue',
            headerName: 'Condition Value',
            type: 'singleSelect',
            valueOptions: (params) => getConditionValueOptions(params.row),
            minWidth: 140,
            flex: 0.25,
            valueSetter: (params) => {
                const { row, value } = params;
                if (Object.prototype.toString.call(value) === '[object Date]') {
                    const valueWithTImeZoneOffset = TimeZoneHelper.parseUtcDate(
                        new Date(value),
                        timeZone
                    );

                    const isDateValid = TimeZoneHelper.isDateValid(
                        valueWithTImeZoneOffset
                    );

                    return {
                        ...row,
                        conditionValue: isDateValid
                            ? valueWithTImeZoneOffset.toISOString()
                            : '',
                    };
                }

                return {
                    ...row,
                    conditionValue: value,
                };
            },
            cellClassName: (params) => {
                let cellClass = 'cell-text-input condition-value';

                const isLogicEnabled = params.row.isDisplayLogicEnabled;
                const hasParent = params.row.logicParentCustomId;
                const isParentAnswerIdentified = isParentAnswerDescribed(
                    params.row
                );
                if (
                    !isLogicEnabled ||
                    !hasParent ||
                    !isParentAnswerIdentified
                ) {
                    cellClass = `${cellClass} disabled`;
                }

                return cellClass;
            },
            editable: true,
            align: 'left',
            headerAlign: 'left',
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                if (
                    Object.prototype.toString.call(params.props.value) ===
                    '[object Date]'
                ) {
                    return { ...params.props, error: !!!params.props.value };
                }
                const valueLenght = params.props.value?.trim().lenght;
                const hasError = valueLenght <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => {
                const currentColumn = params.row as DataTableColumn;
                const parentColumnDefinition = getColumnGridModelByCusomtId(
                    currentColumn.logicParentCustomId
                );
                const parentAnswerType = AnswerTypeVariableNamesList.find(
                    (type) => type.name === parentColumnDefinition?.answerType
                )?.id;

                switch (parentAnswerType) {
                    case AnswerTypeVariable.Text:
                        return <TextInputColumn {...params} />;
                    case AnswerTypeVariable.Number:
                        return (
                            <div className="numeric-cell-edit">
                                <NumericColumn
                                    isReadonly={false}
                                    formType={parentColumnDefinition.formType}
                                    {...params}
                                />
                            </div>
                        );
                    case AnswerTypeVariable.Form:
                        return (
                            <GridEditSingleSelectCell
                                onValueChange={(_, newValue) => {
                                    handleConditionValueDropdownChange(
                                        newValue,
                                        params.id
                                    );
                                }}
                                {...params}
                            />
                        );
                    case AnswerTypeVariable.Date:
                        return (
                            <GridEditDateCell
                                onValueChange={(_, newValue) => {
                                    handleConditionDateValueChange(
                                        newValue,
                                        params.id
                                    );
                                }}
                                {...params}
                            />
                        );
                    default:
                        return <></>;
                }
            },
            renderCell: (params) => {
                const currentColumn = params.row as DataTableColumn;
                const parentColumnDefinition = getColumnGridModelByCusomtId(
                    currentColumn.logicParentCustomId
                );
                const parentAnswerType = AnswerTypeVariableNamesList.find(
                    (type) => type.name === parentColumnDefinition?.answerType
                )?.id;

                switch (parentAnswerType) {
                    case AnswerTypeVariable.Number:
                        return (
                            <Box className="MuiDataGrid-cellContent custom-render-cell">
                                <NumericColumn
                                    isReadonly={true}
                                    formType={parentColumnDefinition.formType}
                                    {...params}
                                />
                            </Box>
                        );
                    case AnswerTypeVariable.Date:
                        return (
                            <Box className="MuiDataGrid-cellContent custom-render-cell">
                                <div>
                                    {params?.value
                                        ? moment(params?.value).format('L')
                                        : null}
                                </div>
                            </Box>
                        );
                    default:
                        return (
                            <Box className="MuiDataGrid-cellContent custom-render-cell">
                                <div>{params.value}</div>
                            </Box>
                        );
                }
            },
        },
    ];

    const runValidation = (
        column: DataTableColumn,
        element: Element
    ): boolean => {
        let isValid = true;
        if (validateRequiredCell(element, '.display-name', column.name)) {
            isValid = false;
        }
        if (validateRequiredCell(element, '.answer-type', column.answerType)) {
            isValid = false;
        }

        if (column.isDisplayLogicEnabled) {
            if (
                validateRequiredCell(
                    element,
                    '.logic-parent-column-id',
                    column.logicParentCustomId
                )
            ) {
                isValid = false;
            }

            if (column.logicParentCustomId && isParentAnswerDescribed(column)) {
                const validationResult = validateRequiredCell(
                    element,
                    '.condition-value',
                    column.conditionValue
                );
                isValid = validationResult ? false : isValid;
            } else {
                const displayNameCellElement =
                    element.querySelector('.condition-value');
                removeErrorClassFromCellContent(displayNameCellElement);
            }
        } else {
            const logicParentIdCellElement = element.querySelector(
                '.logic-parent-column-id'
            );
            removeErrorClassFromCellContent(logicParentIdCellElement);

            const conditionValueCellElement =
                element.querySelector('.condition-value');
            removeErrorClassFromCellContent(conditionValueCellElement);
        }

        return isValid;
    };

    const validateAllRows = () => {
        let isValid = true;
        for (let index = 0; index < tableColumns.length; index++) {
            const rowId = tableColumns[index].customColumnId;
            const element = document.querySelector('[data-id="' + rowId + '"]');
            const column = tableColumns[index];
            if (element) {
                const isColumnValid = runValidation(column, element);
                if (!isColumnValid) {
                    isValid = false;
                }
            }
        }

        apiRef.current.forceUpdate();
        if (
            !validateGridEditorRowDisplayOrder(
                tableColumns,
                apiRef.current.state,
                EditorTypeEnum.DataTable
            )
        ) {
            isValid = false;
        }

        const mappedDisplayNameColumns = tableColumns.map((m) => ({
            ...m,
            displayName: m.name,
        }));
        if (
            !validateUniqueItemName(
                mappedDisplayNameColumns,
                apiRef.current.state,
                EditorTypeEnum.DataTable
            )
        ) {
            isValid = false;
        }
        if (
            !validateFormType(
                tableColumns,
                apiRef.current.state,
                EditorTypeEnum.DataTable
            )
        ) {
            isValid = false;
        }

        if (
            !validateFormList(
                tableColumns,
                apiRef.current.state,
                EditorTypeEnum.DataTable
            )
        ) {
            isValid = false;
        }

        return isValid;
    };

    useEffect(() => {
        validateAllRows();
    }, [tableColumns]);

    return (
        <div className="data-table-editor">
            <EditableTable
                editorType={EditorTypeEnum.DataTable}
                columns={columns()}
                rows={tableColumns}
                setRows={updateRows}
                isLoading={isLoading}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
            />
        </div>
    );
});

export default DataTableEditor;
