import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../../../models/DataRequestHub/DataRequestProject';
import {
    DataTable,
    DataTableView,
    DataTableViewColumn,
} from '../../../../../models/DataRequestHub/DataTable';
import { DataTableEditorModeEnum } from '../ProjectEditorHost';
import { TableSettingsPopup } from '../../Popups/TableSettingsPopup/TableSettingsPopup';

import DataTableViewEditor from './DataTableViewEditor';
import { DataTableViewGridRow } from '../../../../../models/DataRequestHub/DataTableViewGridRow';
import { DesignModeSwitcher } from '../../../../DataRequest/DesignModeSwitcher/DesignModeSwitcher';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {
    validateDataTableViews,
    validateProjectDataTables,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import { EditorModeEnum } from '../../../../../models/DataRequestHub/ProjectEditorModeEnum';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';

interface DataTableViewEditorHostProps {
    editMode: EditorModeEnum;
    dataTable: DataTable;
    setDataTableViews(views: DataTableView[]): void;
    isLoading: boolean;
    updateProjectData(): void;
    updateProjectVariables(): void;
    project: DataRequestProject;
    selectedTableViewId?: number;
    closeEditor(): void;
    viewEditorMode: DataTableEditorModeEnum;
}
const DataTableViewEditorHost = forwardRef(
    (props: DataTableViewEditorHostProps, ref) => {
        const editorRef = useRef(null);
        const [isTableSettingsPopupVisible, setIsTableSettingsPopupVisible] =
            useState(false);
        const [selectedTableViewId] = useState(props.selectedTableViewId ?? 0);
        const isTemplateEditor = props.editMode !== EditorModeEnum.EditProject;
        const navigate = useNavigate();

        useImperativeHandle(ref, () => ({
            validateAllRows() {
                const isFormValid = editorRef.current.validateAllRows();

                const areTablesValid = validateProjectDataTables([
                    props.dataTable,
                ]);
                const areViewsValid = validateDataTableViews([props.dataTable]);

                return isFormValid && areViewsValid && areTablesValid;
            },
        }));

        const updateDataTableView = (
            viewId: number,
            updatedTableViewColumns: DataTableViewColumn[]
        ) => {
            const targetView = props.dataTable.views.find(
                (view) => view.id === viewId
            );
            const targetIndex = props.dataTable.views.indexOf(targetView);

            const tableViews = [...props.dataTable.views];
            tableViews[targetIndex].viewColumns = updatedTableViewColumns;

            props.setDataTableViews(tableViews);
        };

        const getTableView = (viewId: number) =>
            props.dataTable?.views?.find((f) => f.id === viewId);

        const renderEditor = () => {
            const table = props.dataTable;
            const view = getTableView(selectedTableViewId);

            return (
                <>
                    {isTemplateEditor ? (
                        <></>
                    ) : props.viewEditorMode ===
                      DataTableEditorModeEnum.EditView ? (
                        <>
                            <div className="stage-navigation-breadcrumb">
                                <label
                                    className="link-text"
                                    onClick={() => props.closeEditor()}>
                                    {'Table'}
                                </label>
                                <label className="node-separator">
                                    {' / '}
                                </label>
                                <label
                                    className="link-text"
                                    onClick={() => props.closeEditor()}>
                                    {table.name}
                                </label>
                                <label className="node-separator">
                                    {' / '}
                                </label>
                                <label className="link-text">{view.name}</label>
                            </div>
                            <div className="editor-header">
                                <Col>
                                    <Row className="header-row">
                                        <Label>
                                            <strong>
                                                {'Data Table Name: '}
                                            </strong>
                                            {table.name}
                                        </Label>
                                        <Label>
                                            <strong>
                                                {'Table View Name: '}
                                            </strong>
                                            {view.name}
                                        </Label>
                                    </Row>
                                </Col>
                                <div className="project-settings-section">
                                    <div
                                        className="clickable"
                                        onClick={() => {
                                            setIsTableSettingsPopupVisible(
                                                true
                                            );
                                        }}>
                                        <FontAwesomeIcon icon={faWrench} />
                                        {'User View Settings'}
                                    </div>
                                    {props.project.state ===
                                        DataRequestProjectState.Active && (
                                        <DesignModeSwitcher
                                            formId={0}
                                            defaultValue={true}
                                            onDisable={() => {
                                                navigate(
                                                    `/data-request-dashboard?tab=${
                                                        EditorTypeEnum.DataTable +
                                                        1
                                                    }`,
                                                    {
                                                        state: {
                                                            preselectedTableId:
                                                                props.dataTable
                                                                    .id,
                                                            preselectedTableViewId:
                                                                selectedTableViewId,
                                                            projectId:
                                                                props.project
                                                                    .id,
                                                            isAdmin: true,
                                                            projectName:
                                                                props.project
                                                                    .name,
                                                            practiceName:
                                                                props.project
                                                                    .practiceName,
                                                        },
                                                    }
                                                );
                                            }}></DesignModeSwitcher>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <DataTableViewEditor
                        tableViewGridRows={getTableColumns(selectedTableViewId)}
                        setTableViewGridRows={(rows: DataTableViewGridRow[]) =>
                            updateTableViewRows(selectedTableViewId, rows)
                        }
                        isLoading={false}
                        ref={editorRef}
                    />
                </>
            );
        };
        const updateTableViewRows = (
            viewId: number,
            rows: DataTableViewGridRow[]
        ) => {
            const visibleViewRows = rows
                .filter((row) => row.isVisible)
                .map(
                    (viewGridRow): DataTableViewColumn => ({
                        id: viewGridRow.tableViewColumnId,
                        customColumnId: viewGridRow.customColumnId,
                        overridenDisplayOrder: viewGridRow.displayOrder,
                        isIncluded: viewGridRow.isVisible,
                        isSeenByAdmin: viewGridRow.isSeenByAdmin,
                        isSeenByUser: viewGridRow.isSeenByUser,
                        isVisible: viewGridRow.isVisible
                    })
                );
            updateDataTableView(viewId, visibleViewRows);
        };

        const getTableColumns = (viewId: number): DataTableViewGridRow[] => {
            const tableColumns = props?.dataTable?.columns;
            const tableView = getTableView(viewId);

            const dataTableViewGridRows =
                tableColumns
                    ?.filter((x) => x.isVisible)
                    .map((tableColumn): DataTableViewGridRow => {
                        const viewColumn = tableView?.viewColumns?.find(
                            (column) =>
                                column.customColumnId ===
                                tableColumn.customColumnId
                        ) ?? {
                            id: 0,
                            customColumnId: tableColumn.customColumnId,
                            isSeenByAdmin: true,
                            isSeenByUser: true,
                            isVisible: true,
                        };

                        return {
                            ...tableColumn,
                            ...viewColumn,
                            tableColumnId: tableColumn.id,
                            tableViewColumnId: viewColumn.id,
                            isVisible: viewColumn.isIncluded,
                            displayOrder: viewColumn.overridenDisplayOrder,
                            customColumnId: tableColumn.customColumnId,
                        };
                    }) ?? [];

            return dataTableViewGridRows;
        };

        return (
            <>
                {renderEditor()}

                {isTableSettingsPopupVisible && (
                    <TableSettingsPopup
                        isVisible={isTableSettingsPopupVisible}
                        setIsVisible={setIsTableSettingsPopupVisible}
                        onSubmit={() => {
                            props.updateProjectData();
                            props.updateProjectVariables();
                        }}
                        projectId={props.project.id}
                        dataTables={props.project.dataTables}
                        dataForms={
                            props.project.dataForms
                        }></TableSettingsPopup>
                )}
            </>
        );
    }
);

export default DataTableViewEditorHost;
